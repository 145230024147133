<script lang="ts" setup>
// Properties

const props = defineProps({
    showModalBox: {
        type: [Boolean, ref],
        default: true,
    },
    backdrop: {
        type: Boolean,
        default: true,
    },
    width: {
        type: String,
        default: "w-5/6 2xl:w-1/2",
    },
});

// Data
const emit = defineEmits(["update:showModalBox"]);

function closeModalBox() {
    emit("update:showModalBox", false);
}
</script>

<template>
    <div
        v-show="showModalBox"
        class="fixed left-0 top-0 z-50 h-full w-full"
        :class="{ 'bg-black/30 backdrop-invert backdrop-opacity-10': backdrop }"
        @click="closeModalBox"
    >
        <transition name="fade">
            <div v-show="showModalBox" class="flex h-full items-center justify-center">
                <div
                    class="relative flex-row overflow-y-auto rounded-xl bg-white p-9 shadow-lg"
                    :class="width"
                    @click.stop=""
                >
                    <NuxtImg
                        src="/images/fa/x-solid.svg"
                        class="absolute right-4 top-4 w-3 cursor-pointer"
                        @click="closeModalBox"
                    ></NuxtImg>

                    <slot name="header"></slot>
                    <div class="text-left">
                        <slot></slot>
                    </div>
                    <slot name="footer"></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped>
.fade-up-down-enter-active {
    transition: all 0.3s ease;
}

.fade-up-down-leave-active {
    transition: all 0.3s ease;
}

.fade-up-down-enter {
    transform: translateY(10%);
    opacity: 0;
}

.fade-up-down-leave-to {
    transform: translateY(10%);
    opacity: 0;
}

.fade-enter-active {
    -webkit-transition: opacity 2s;
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
